#volume-bar {
  height: 7px;
  width: 100px;

  // box-sizing: content-box;
  display: inline;
  position: relative;
  top: 0px;
  left: 7px;
}

#volume-bar::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 2px;
  height: 20px;
  border-radius: 4px;
  cursor: pointer;
}
