@import '_settings.scss';

.controls {
  position: absolute;
  left: 860px;
  top: 0px;
  height: 490px;
  background: $backgroundColor;
  padding: 4px 16.5px;
  margin-left: 0px;
  margin-right: 70px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  align-content: center;
  -ms-flex-line-pack: center;
  -webkit-align-content: center;
}

.controls .command-icon {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;

  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;

  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;

  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
}

.controls .command-icon img:hover {
  -webkit-transform: scale(1.15);
  transform: scale(1.15);
}

.command-icon img {
  outline: none;
}
