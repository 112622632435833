@import '_settings.scss';

html {
  font-size: 62.5%;
  // height: 100%;
}

body {
  position: relative;
  color: white;
  height: 100vh;

  background-color: white;

  background-size: 100%;
  // background-repeat: no-repeat;
  overflow-y: scroll;
  font-family: 'Muli', 'Open Sans', Helvetica, Arial, sans-serif;
  font-size: $m-size;
}

.is-active {
  font-weight: bold;
}
