#save-modal {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 9999; /* Sit on top */
  padding-top: 200px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content */
#save-modal-content {
  border-radius: 10px;
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 30%;
}

#modal-heading {
  font-size: 25px;
  color: black;
  text-align: center;
}

#modal-message {
  color: black;
  font-size: 17px;
  margin: 10px;
}
/* The Close Button */
#close {
  color: white;
  width: 70px;
  font-size: 20px;
  font-weight: bold;
  border: 0;
  cursor: pointer;
  background: black;
  border-radius: 7px;
}

#close:focus {
  outline: 0;
}

#save-progress {
  width: 300px;
  height: 14px;
  margin: auto;
  margin-top: 20px;
  display: block;
  -webkit-appearance: none;
}

#save-progress::-webkit-progress-bar {
  background: rgb(160, 160, 160);
  border-radius: 50px;
  padding: 2px;
  height: 10px;
}

#save-progress::-webkit-progress-value {
  border-radius: 50px;
  background: #d24727;
}
