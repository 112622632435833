#waveform {
  width: 500px;
  margin-top: 15px;
  margin-left: 55px;
}

#wave-timeline {
  position: absolute;
  width: 500px;
  left: 55px;
  color: white;
}
