@import "_settings.scss";

#settings-modal {
  width: 700px;
  max-width: 700px;
}

#settings-modal .modal-content {
  background-color: #212121;
}

#settings-modal .modal-header {
  border-bottom: 0px;
}

#settings-modal .modal-header > .close {
  opacity: 1;
  text-shadow: 0px 0px;
}

#settings-modal .modal-header > .close > span {
  color: #9f9f9f;
  font-size: 21px;
}

#settings-modal-body {
  text-align: center;
  padding-top: 0px;
}

#settings-modal-body .panel {
  border-color: #333;
  border: 0px !important;
  border-radius: 0px;
  margin-bottom: 20px;
}

#settings-modal-body .panel-heading {
  background-color: #333;
  border-color: #333;
  color: #9f9f9f;
  font-size: 20px;
  border: 0px !important;
  border-radius: 0px;
}

#settings-modal-body .panel-heading > span {
  font-size: 80%;
}

#settings-modal-body .panel-body {
  background-color: #333;
}

#settings-modal-body .video-quality-input {
  margin: 10px;
}

.transparency-box-left,
.transparency-box-right {
  width: 30px;
  height: 30px;
  border: 4px solid $backgroundColor;
  display: inline-block;
  margin-left: 20px;
  margin-right: 20px;
}

.transparency-box-right {
  background-color: rgb(160, 160, 160);
}

#transparency_level_bar {
  width: 150px;
  height: 7px;
  display: inline;
  position: relative;
  top: -10px;
}

#mute-tone-volume-button {
  padding: 2px 0px 0px 0px;
  height: 28px;
  vertical-align: baseline;
}

#mute-tone-volume-bar {
  width: 150px;
  height: 7px;
  display: inline;
  position: relative;
  top: -10px;
  margin-left: 10px;
}

.redaction-box-blur.selected {
  border: solid 6px $backgroundColor;
}
