@import '_settings.scss';

#path-selector-box {
  position: absolute;
  left: 923px;
  top: 0px;
  padding: 10px 10px 10px 0px;
  overflow-y: auto;
  height: 480px;
  width: 240px;
  font-size: 14px;
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.trackobject {
  padding-top: 15px;
  padding-bottom: 1px;
  padding-left: 10px;
  border-bottom: 1px solid #555;
}

#box {
  padding: 3px;
  border: 1px solid rgb(246, 135, 47);
  display: block;
}

.label {
  color: black;
  font-size: 100%;
}

.trackobject-selected {
  background-color: rgb(214, 214, 214);
}

.img-frame {
  display: inline-block;
  border-width: 2px;
  border-style: solid;
  position: relative;
  top: -25px;
  height: 17px;
  width: 17px;
  padding-left: 10px;
}

.eye {
  margin-left: 5px;
  margin-right: 5px;
  width: 70px;
  height: 45px;
  display: inline-block;
  position: relative;
  top: -12px;
}

.not-visible-icon {
  float: right;
  width: 70px;
  height: 45px;
}

.del {
  // width: 20px;
  // height: 20px;
  display: inline;
}

.delete-icon {
  float: right;
  width: 20px;
  height: 20px;
}

.trackobjectheader {
  color: black;
  cursor: default;
  font-size: 16px;
  display: block;
  font-family: 'Muli', 'Open Sans', Helvetica, Arial, sans-serif;
}

.trackobjectdetails {
  display: block;
}
