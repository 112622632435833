.dropdown {
  display: inline;
  margin-top: 15px;
}

.dropdown-toggle {
  color: white !important;
}

.dropdown button {
  color: white;
  min-width: 60px;
  position: relative;
  top: -9px;
  font-size: 17px;
  background: rgba(0, 0, 0, 0);
  font-weight: bold;
}

#playback_speed_selector {
  left: -15px;
  min-width: 80px;
}

#playback_speed_selector li {
  text-align: center;
}

#playback_speed_selector a {
  font-size: 16px;
  font-weight: bold;
  outline: none;
  color: #333;
  text-decoration: none;
}

#playback_speed_selector a:hover {
  color: white;
  background-color: rgb(122, 166, 68);
}
