.alert-dialog {
  top: 0px;
  border: 0px;
  border-radius: 4px;
  padding-bottom: 0px;
  width: 400px;
  height: 120px;
}

.alert-button {
  border-radius: 4px;
  margin-top: 20px;
  width: 70px;
  height: 25px;
  background-color: black;
  border: 0;
  color: white;
  cursor: pointer;
  font-size: 14px;
  float: right;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
}
.alert-button:focus {
  outline: 0px;
}

.alert-text {
  font-size: 15px;
}
