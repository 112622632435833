#merge-paths-modal{
    width: 600px;
    max-width: 700px;
}

#merge-paths-modal .modal-content{
    background-color: #212121;
    color: #9f9f9f;
}

.modal-header{
    border-bottom: none;
}

#merge-paths-modal .modal-header>.close{
    opacity: 1.0;
    text-shadow: 0px 0px;
}

#merge-paths-modal .modal-header>.close>span{
    color: #9f9f9f;
    font-size: 21px;
}

.modal-title{
    // color: black;
}

#merge-paths-modal-body{
    text-align: center;
    font-size: 16px;
    font-weight: bold;
}

#merge-box{
    height: 430px;
    overflow-y: scroll;
}

.merge-box_element{
    display: inline-block;
    width: 350px;
    height: 120px;
    font-size: 20px;
    border-top: 1px solid #666;
    border-right: 1px solid #666;
    border-bottom: 1px solid #666;
    border-left: 1px solid #666;
}

.merge-box_labels{
    color: #9f9f9f;
    position: relative;
    top: 4px;
}

#merge-paths-modal-cancel{
    font-size: 16px;
    font-weight: 500;
    color: #c6c6c6;
    border: 1px solid #717171;
    border-radius: 0;
    background-color: #292929;
    padding: 10px 20px;
    outline: none;
    background: rgba(0,0,0,.5);
}

#merge-paths-submit-button{
    border-radius: 0px;
    font-family: "Open Sans", "Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 16px;
    font-weight: bold;
    color: #3f3f3f;
    background-color: $backgroundColor;
    margin-left: 15px;
    padding: 10px 20px;
    outline: none;
}