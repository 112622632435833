#time-elapsed {
  position: relative;
  height: 20px;
  font-size: 15px;
  font-weight: bolder;
  font-family: Muli, sans-serif;
  color: white;
  min-width: 180px;
  padding: 0px 2px 0px 5px;
  top: -2px;
}
