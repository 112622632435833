#theater {
  width: 852px;
  height: 480px;
}

#theater span {
  display: block;
  width: 852px;
  height: 480px;
}

#video {
  position: absolute;
  top: 0px;
  left: 0;
  // width: 860px;
  // height: 490px;
  /* display: none; */
}

#video_jpg_overlay {
  position: absolute;
  top: 0;
  left: 0;
  // width: 860px;
  // height: 480px;
}
