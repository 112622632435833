@import '_settings.scss';

#video-controls {
  box-sizing: content-box;
  padding: 20px 0px 10px 5px;
  opacity: 100;
  transition: opacity 0.3s;
  height: 35px;
  width: 920px;
  background-color: $backgroundColor;
  display: flex;
  flex-direction: row;
  align-items: center;
}
