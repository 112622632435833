@import '_settings.scss';

#toolbar-new-body-auto-redaction {
  border: initial;
  background: initial;
  margin-right: initial;
}

#close-body-auto-redaction {
  float: right;
  cursor: pointer;
}

#body-already-redacted-display {
  margin-bottom: 10px;
  margin-top: 10px;
  font-size: 12px;
}

#toolbar-new-body-auto-redaction .popover {
  width: 200px;
  // height: 240px;
  color: $backgroundColor;
  margin-left: 10px;
}

.popover-header {
  // font-weight: 400;
  font-size: 18px;
}

label {
  font-weight: 700;
  font-size: 10px;
  width: 60px;
  color: $backgroundColor;
}

#body-start-redact-region {
  width: 100px;
  font-size: 15px;
}

#body-end-redact-region {
  width: 100px;
  font-size: 15px;
}

.arrow::before,
.arrow::after {
  border-width: 1rem 1rem 1rem 0;
}
