//Colors

$off-black: #20222b;
$off-white: #a5afd7;
$dark-blue: #333745;
$purple: #8357c5;
$blue: #3c4251;
$light-blue: #464b5e;

$sky-blue: #1e73be;
$green: rgb(122, 166, 68);
$antiqueWhite: #faebd7;
$aqua: #00ffff;
$aquamarine: #7fffd4;
$brown: #a52a2a;
$cadetBlue: #5f9ea0;
$CornflowerBlue: #6495ed;
$darkBlue: #00008b;
$deepPink: #ff1493;
$darkTurquoise: #00ced1;

:root {
  // --bg: rgb(122, 166, 68);
  --bg: rgb(246, 135, 47);
}

// $backgroundColor: $sky-blue;
$backgroundColor: var(--bg);

//fonts

//spacing
$s-size: 1.2rem;
$m-size: 1.6rem;
$l-size: 3.2rem;
$xl-size: 4.8rem;

$desktop-breakpoint: 45rem;
