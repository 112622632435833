#flag_pointer_container {
  position: relative;
  display: flex;
}

#flag_pointer_container div {
  position: relative;
  // top: -4px;
  display: inline;
  // margin-top: 10px;
  // width: 450px;
  // padding-left: 10px;
}

#seek-bar {
  display: inline;
  height: 7px;
  width: 480px;
  // box-sizing: content-box;
}

.vievu-slider {
  -webkit-appearance: none;
  height: 30px;
  padding: 0px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.vievu-slider:hover {
  opacity: 1;
}

.vievu-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 2px;
  height: 20px;
  border-radius: 4px;
  background: #faf7f7;
  cursor: pointer;
}

.vievu-slider::-moz-range-thumb {
  width: 2px;
  height: 20px;
  border-radius: 4px;
  background: #f6f8f6;
  cursor: pointer;
}
