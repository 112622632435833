.img-btn {
    background-color: Transparent;
    border-block-end: 0vmax;
    border: 0px;
    padding: 0;
    width: 32px;
    height: 32px;
}

.img-btn:focus {
    outline: 0;
}
