@import '_settings.scss';

#redactPage {
  background-color: #212121;
  color: $backgroundColor;
  user-select: none;
  height: 883px;
}

#canvas2 {
  /* display: none; */
  position: absolute;
  top: 0px;
  left: 0;
}

#canvas {
  z-index: 2;
  position: absolute;
  width: 852px;
  height: 480px;
  top: 0px;
  left: 0;
  cursor: default;
}

#loadingVideo {
  color: #8b8b8b;
  font-size: 30px;
  font-weight: bold;
  font-family: Arial Black, Arial Bold, Gadget, sans-serif;
  position: relative;
  top: 40%;
  left: 30%;
}

#video-load-progress {
  position: relative;
  top: 40%;
  left: 30%;
  width: 27%;
  height: 10px;
}

.ui-corner-all,
.ui-corner-bottom,
.ui-corner-right,
.ui-corner-br {
  border-bottom-right-radius: 3px;
}

.ui-corner-all,
.ui-corner-bottom,
.ui-corner-left,
.ui-corner-bl {
  border-bottom-left-radius: 3px;
}

.ui-corner-all,
.ui-corner-top,
.ui-corner-right,
.ui-corner-tr {
  border-top-right-radius: 3px;
}

.ui-corner-all,
.ui-corner-top,
.ui-corner-left,
.ui-corner-tl {
  border-top-left-radius: 3px;
}

.ui-widget-content {
  border: 1px solid #aaaaaa;
  // background: #ffffff url(../images/ui-bg_flat_75_ffffff_40x100.png) 50% 50% repeat-x;
  color: #222222;
}

.ui-widget {
  font-family: Verdana, Arial, sans-serif;
  font-size: 1.1em;
}

.ui-widget-header {
  border: 1px solid #aaaaaa;
  color: #222222;
  font-weight: bold;
}

.ui-progressbar {
  height: 2em;
  text-align: left;
  overflow: hidden;
}

#video-load-progress-bar {
  width: 0%;
  height: 10px;
  background-color: $backgroundColor;
}

#slider-preview {
  border: 1px solid rgb(33, 33, 33);
  position: absolute;
  width: 200px;
  height: 113px;
  display: none;
}

#slider-preview-ts {
  background-color: rgb(20, 20, 20);
  color: white;
  text-align: center;
  position: absolute;
  width: 80px;
  height: 20px;
  display: none;
}
