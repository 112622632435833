.vcrosshair {
  width: 2px;
  height: 100%;
  position: relative;
  top: -2px;
  left: 0px;
  z-index: 1;
  /* background-color: green; */
}

.hcrosshair {
  height: 2px;
  width: 100%;
  position: relative;
  top: 0px;
  left: 0px;
  z-index: 1;
  /* background-color: green;     */
}

.command-icon img.disabled {
  filter: opacity(30%);
  -webkit-filter: opacity(30%);
  cursor: default;
}

.command-icon img.disabled:hover {
  transform: scale(1) !important;
  -webkit-transform: scale(1) !important;
  -moz-transform: scale(1) !important;
}

.boundingbox {
  position: absolute !important;
  border-style: solid;
  border-width: 3px;
  z-index: 5;
}

.boundingboxtext {
  cursor: default;
  pointer-events: none;
  padding: 5px 0px 0px 5px;
  color: #ffffff;
}
