.boundingbox {
  background-color: rgba(160, 160, 160, 0.2);
}

.boundingboxtext {
  display: block;
  cursor: default;
  pointer-events: none;
  padding: 5px 0px 0px 5px;
  color: #ffffff;
}

.fill {
  background-color: rgb(0, 0, 0);
}

.ui-resizable-handle {
  z-index: 90;
  display: none;
}

strong {
  user-select: none;
}
