@import './_settings.scss';

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  // background: $off-black;
  background: rgba(220, 94, 94, 0.74);
  height: 80px;
}

.header__title {
  font-size: $l-size;
  // width: 450px;
  width: 510px;
  color: white;
}

.refreshBtn {
  cursor: pointer;
}

@media (max-width: 850px) {
  .header__title {
    width: 325px;
  }
}
