@import '_settings.scss';

#toolbar-redact-audio {
  border: initial;
  background: initial;
  margin-right: initial;
}

#close-audio-redaction {
  float: right;
  cursor: pointer;
}

#toolbar-redact-audio .popover {
  width: 200px;
  color: $backgroundColor;
  margin-left: 10px;
}

label {
  font-weight: 700;
  font-size: 10px;
  width: 60px;
  color: rgb(122, 166, 68);
}

#audio-start-redact-region {
  width: 100px;
  font-size: 15px;
}

#audio-end-redact-region {
  width: 100px;
  font-size: 15px;
}

.arrow::before,
.arrow::after {
  border-width: 1rem 1rem 1rem 0;
}
