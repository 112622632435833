#keyboard-shortcuts-modal{
    width: 600px;
    max-width: 600px;
}

#keyboard-shortcuts-modal .modal-content{
    // background-color: #212121;
    color: black;
}

#keyboard-shortcuts-modal .modal-header{
    border-bottom: 0px;
}

#keyboard-shortcuts-modal .modal-header>.close{
    opacity: 1.0;
    text-shadow: 0px 0px;
}

#keyboard-shortcuts-modal .modal-header>.close>span{
    color: #9f9f9f;
    font-size: 21px;
}

#keyboard-shortcuts-modal-body{
    text-align: center;
    padding-top: 0px;
}

#keyboard-shortcuts-modal-body h3{
    margin-top: 0px;    
    margin-bottom: 0px;
    color: black;
    font-size: 24px;
}

#keyboard-shortcuts-table{
    text-align: center; 
    width: 100%;
}

#keyboard-shortcut-table tr td 
{
    color: black;
    padding: 0px 10px 0px 10px;
    text-align: center;
}
